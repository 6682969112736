import { Space } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"
import styles from "./Logo.module.scss"

interface LogoProps {
  img: string
  height: number
}

const Logo: FC<LogoProps> = ({ img, height }) => {
  return (
    <Space direction={"horizontal"} align={"center"}>
      <Link to={"/"} className={styles.logo}>
        <img src={img} alt={"logo"} width={"auto"} height={height} />
      </Link>
    </Space>
  )
}

export { Logo }
