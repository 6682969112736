import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect } from "react"
import { Toaster } from "react-hot-toast"
import { Outlet, useLocation } from "react-router-dom"
import { isLiteHeaderColorText } from "src/helpers/isLiteHeaderColor"
import { useBreakpoint } from "src/hooks/useBreakPoint"
import { useWindowScrollPositions } from "src/hooks/useWindowScrollPosition"
import { Footer, Header } from "."

const Layout: FC = () => {
  const { xl } = useBreakpoint()
  const { pathname } = useLocation()
  const { scrollY } = useWindowScrollPositions()
  const isLiteColor = isLiteHeaderColorText(pathname)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    setHeight()

    window.addEventListener("resize", setHeight)
    return () => {
      window.removeEventListener("resize", setHeight)
    }
  }, [])

  return (
    <BaseLayout className={"baseLayoutStyle"}>
      <BaseLayout.Header
        style={{
          borderBottom: "1px solid #D7DCE5",
          backdropFilter: "blur(2px)",
          backgroundColor: scrollY >= 100 ? (isLiteColor ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.6)") : "unset",
          height: "auto",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <Header />
      </BaseLayout.Header>
      <BaseLayout style={{ padding: 0 }}>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer
        style={{
          padding: xl ? "96px 0 40px 0" : "40px 0",
        }}
      >
        <Footer />
      </BaseLayout.Footer>
      <Toaster />
    </BaseLayout>
  )
}

export { Layout }
