import { Col, Flex, Row, Typography } from "antd"
import { FC } from "react"
import { MdOutlineMailOutline } from "react-icons/md"
import { TbWorld } from "react-icons/tb"
import { Link } from "react-router-dom"
import Container from "src/components/container"
import { SocialMediaList } from "src/components/socialMediaList"
import { useFooterQuery } from "src/graphql"
import { useBreakpoint } from "src/hooks/useBreakPoint"
import { Logo } from "../../logo"
import styles from "./Footer.module.scss"
import { formattedData } from "./formattedData"

const Footer: FC = () => {
  const { data } = useFooterQuery()

  const preparedData = data ? formattedData(data) : null

  const { xl } = useBreakpoint()
  return preparedData ? (
    <Container>
      <Flex className={styles.topWrapper} vertical={xl ? false : true}>
        <Col style={{ marginBottom: xl ? "0" : "40px" }}>
          {preparedData.logo ? <Logo img={preparedData.logo} height={xl ? 24 : 16} /> : null}
          <p
            style={{ color: "#FFF", marginTop: xl ? "30px" : "0" }}
            dangerouslySetInnerHTML={{ __html: preparedData.copyright }}
          ></p>
        </Col>

        <Flex style={{ marginLeft: xl ? "auto" : "0" }} gap={xl ? 88 : 0}>
          <Row gutter={[0, 16]}>
            {preparedData.navLinks.map(({ id, link, name, icon, target }) => (
              <Col key={id} span={xl && preparedData.navLinks.length > 6 ? 6 : 12} className={styles.linkText}>
                <Link to={link} style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  {icon ? <img src={icon} alt={"icon"} width={18} style={{ maxWidth: "unset" }} /> : null}
                  {name}
                </Link>
              </Col>
            ))}
          </Row>

          <Flex vertical className={styles.linkText} gap={16}>
            {preparedData.contactLinks.map(({ id, link, name, icon, target }) => {
              const isEmail = link.includes("@")
              const href = isEmail ? `mailto:${link}` : link

              return (
                <a
                  key={id}
                  href={href ? href : ""}
                  target={`_${target}`}
                  rel={"noreferrer"}
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  {icon ? <img src={icon} alt={"icon"} width={18} style={{ maxWidth: "unset" }} /> : null}
                  {!icon ? isEmail ? <MdOutlineMailOutline size={18} /> : <TbWorld size={18} /> : null}
                  {name}
                </a>
              )
            })}
          </Flex>
        </Flex>
      </Flex>

      <Row className={styles.bootomWrapper} justify={"space-between"}>
        <Col order={xl ? 2 : 1} style={{ marginBottom: xl ? "0" : "16px" }}>
          <Flex gap={"10px 32px"} wrap={preparedData.policy.length > 2 ? true : false}>
            {preparedData.policy.map(({ id, link, name, icon, target }) => (
              <Link
                key={id}
                className={styles.contactText}
                to={link}
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                {icon ? <img src={icon} alt={"icon"} width={18} style={{ maxWidth: "unset" }} /> : null}
                {name}
              </Link>
            ))}
          </Flex>
        </Col>

        <Col order={xl ? 1 : 2}>
          <SocialMediaList />
        </Col>
      </Row>
    </Container>
  ) : null
}

export { Footer }
