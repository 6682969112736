import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUsPage",
      "AccountType",
      "Broker",
      "BrokerNews",
      "BrokerPage",
      "ComponentDataCompanyAndService",
      "ComponentDataContactOffice",
      "ComponentDataContacts",
      "ComponentDataDepositAndWithdrawal",
      "ComponentDataEntry",
      "ComponentDataLicenceStatus",
      "ComponentDataOffice",
      "ComponentDataPaymentMethod",
      "ComponentDataRate",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiCard2",
      "ComponentUiCard3",
      "ComponentUiCard4",
      "ComponentUiCard5",
      "ComponentUiCard6",
      "ComponentUiCard7",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiHeadline2",
      "ComponentUiLink",
      "ComponentUiLink2",
      "ComponentUiOurExpertise",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSection2",
      "ComponentUiSection3",
      "ComponentUiSection4",
      "ComponentUiSection6",
      "ComponentUiSection7",
      "ComponentUiSection8",
      "ComponentUiSection9",
      "ComponentUiSection10",
      "ComponentUiSection11",
      "ComponentUiSection12",
      "ComponentUiSection13",
      "ComponentUiTab",
      "ComponentUiTitle",
      "ContactFormRequest",
      "Currency",
      "Footer",
      "Header",
      "Home",
      "IntroducingBroker",
      "News",
      "NewsPage",
      "PaymentMethod",
      "Podcast",
      "PrivacyPolicy",
      "Rating",
      "ReactIconsIconlibrary",
      "Regulator",
      "ServicesPage",
      "SpreadType",
      "Subscriber",
      "TermsAndCondition",
      "TradingInstrument",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUsPage",
      "AccountType",
      "Broker",
      "BrokerNews",
      "BrokerPage",
      "ComponentDataCompanyAndService",
      "ComponentDataContactOffice",
      "ComponentDataContacts",
      "ComponentDataDepositAndWithdrawal",
      "ComponentDataEntry",
      "ComponentDataLicenceStatus",
      "ComponentDataOffice",
      "ComponentDataPaymentMethod",
      "ComponentDataRate",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiCard2",
      "ComponentUiCard3",
      "ComponentUiCard4",
      "ComponentUiCard5",
      "ComponentUiCard6",
      "ComponentUiCard7",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiHeadline2",
      "ComponentUiLink",
      "ComponentUiLink2",
      "ComponentUiOurExpertise",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSection2",
      "ComponentUiSection3",
      "ComponentUiSection4",
      "ComponentUiSection6",
      "ComponentUiSection7",
      "ComponentUiSection8",
      "ComponentUiSection9",
      "ComponentUiSection10",
      "ComponentUiSection11",
      "ComponentUiSection12",
      "ComponentUiSection13",
      "ComponentUiTab",
      "ComponentUiTitle",
      "ContactFormRequest",
      "Currency",
      "Footer",
      "Header",
      "Home",
      "IntroducingBroker",
      "News",
      "NewsPage",
      "PaymentMethod",
      "Podcast",
      "PrivacyPolicy",
      "Rating",
      "ReactIconsIconlibrary",
      "Regulator",
      "ServicesPage",
      "SpreadType",
      "Subscriber",
      "TermsAndCondition",
      "TradingInstrument",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  name
  description
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
  image {
    data {
      ...File
    }
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  visible
  image_large {
    data {
      ...File
    }
  }
  image_small {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const CreateContactFormRequestDocument = gql`
    mutation createContactFormRequest($input: ContactFormRequestInput!) {
  createContactFormRequest(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateContactFormRequestMutationFn = Apollo.MutationFunction<CreateContactFormRequestMutation, CreateContactFormRequestMutationVariables>;
export function useCreateContactFormRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactFormRequestMutation, CreateContactFormRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactFormRequestMutation, CreateContactFormRequestMutationVariables>(CreateContactFormRequestDocument, options);
      }
export type CreateContactFormRequestMutationHookResult = ReturnType<typeof useCreateContactFormRequestMutation>;
export type CreateContactFormRequestMutationResult = Apollo.MutationResult<CreateContactFormRequestMutation>;
export const CreateIntroducingBrokerDocument = gql`
    mutation createIntroducingBroker($input: IntroducingBrokerInput!) {
  createIntroducingBroker(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateIntroducingBrokerMutationFn = Apollo.MutationFunction<CreateIntroducingBrokerMutation, CreateIntroducingBrokerMutationVariables>;
export function useCreateIntroducingBrokerMutation(baseOptions?: Apollo.MutationHookOptions<CreateIntroducingBrokerMutation, CreateIntroducingBrokerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIntroducingBrokerMutation, CreateIntroducingBrokerMutationVariables>(CreateIntroducingBrokerDocument, options);
      }
export type CreateIntroducingBrokerMutationHookResult = ReturnType<typeof useCreateIntroducingBrokerMutation>;
export type CreateIntroducingBrokerMutationResult = Apollo.MutationResult<CreateIntroducingBrokerMutation>;
export const CreateRatingDocument = gql`
    mutation createRating($input: RatingInput!) {
  createRating(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateRatingMutationFn = Apollo.MutationFunction<CreateRatingMutation, CreateRatingMutationVariables>;
export function useCreateRatingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRatingMutation, CreateRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRatingMutation, CreateRatingMutationVariables>(CreateRatingDocument, options);
      }
export type CreateRatingMutationHookResult = ReturnType<typeof useCreateRatingMutation>;
export type CreateRatingMutationResult = Apollo.MutationResult<CreateRatingMutation>;
export const CreateSubscriberDocument = gql`
    mutation createSubscriber($input: SubscriberInput!) {
  createSubscriber(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateSubscriberMutationFn = Apollo.MutationFunction<CreateSubscriberMutation, CreateSubscriberMutationVariables>;
export function useCreateSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriberMutation, CreateSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriberMutation, CreateSubscriberMutationVariables>(CreateSubscriberDocument, options);
      }
export type CreateSubscriberMutationHookResult = ReturnType<typeof useCreateSubscriberMutation>;
export type CreateSubscriberMutationResult = Apollo.MutationResult<CreateSubscriberMutation>;
export const UpdateBrokerRatingDocument = gql`
    mutation updateBrokerRating($input: BrokerInput!, $id: ID!) {
  updateBroker(data: $input, id: $id) {
    data {
      id
      attributes {
        averageRating {
          id
          depositAndWithdrawal
          overall
          support
          tradingPlatform
          tradingCost
        }
      }
    }
  }
}
    `;
export type UpdateBrokerRatingMutationFn = Apollo.MutationFunction<UpdateBrokerRatingMutation, UpdateBrokerRatingMutationVariables>;
export function useUpdateBrokerRatingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrokerRatingMutation, UpdateBrokerRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrokerRatingMutation, UpdateBrokerRatingMutationVariables>(UpdateBrokerRatingDocument, options);
      }
export type UpdateBrokerRatingMutationHookResult = ReturnType<typeof useUpdateBrokerRatingMutation>;
export type UpdateBrokerRatingMutationResult = Apollo.MutationResult<UpdateBrokerRatingMutation>;
export const VisitCounterDocument = gql`
    mutation visitCounter($id: String!) {
  visitCounter(input: {brokerId: $id}) {
    visits
  }
}
    `;
export type VisitCounterMutationFn = Apollo.MutationFunction<VisitCounterMutation, VisitCounterMutationVariables>;
export function useVisitCounterMutation(baseOptions?: Apollo.MutationHookOptions<VisitCounterMutation, VisitCounterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitCounterMutation, VisitCounterMutationVariables>(VisitCounterDocument, options);
      }
export type VisitCounterMutationHookResult = ReturnType<typeof useVisitCounterMutation>;
export type VisitCounterMutationResult = Apollo.MutationResult<VisitCounterMutation>;
export const AboutUsPageDocument = gql`
    query aboutUsPage {
  aboutUsPage {
    data {
      id
      attributes {
        hero {
          id
          visible
          title
          button_1 {
            id
            name
            url
          }
          button_2 {
            id
            name
            url
          }
        }
        ourApproach {
          id
          title
          description
          visible
          card {
            id
            name
            description
          }
        }
        educationalOfferings {
          id
          visible
          title
          note_title
          note_description
          items {
            id
            name
            description
          }
          image_desktop {
            data {
              ...File
            }
          }
          image_mobile {
            data {
              ...File
            }
          }
        }
        specialOffers {
          id
          title
          description
          visible
          card {
            id
            name
            description
          }
        }
        ourServices {
          id
          title
          subtitle
          visible
        }
        our_services_cards {
          id
          image {
            data {
              ...File
            }
          }
          items {
            id
            name
            description
          }
        }
        community {
          id
          visible
          title
          image_desktop {
            data {
              ...File
            }
          }
          image_mobile {
            data {
              ...File
            }
          }
          cards {
            id
            name
            description
          }
        }
        customerSupport {
          id
          visible
          title
          email
          website
          note_title
          note_description
          cards {
            id
            name
            description
          }
          section_1 {
            id
            title
            description
            image {
              data {
                ...File
              }
            }
          }
          section_2 {
            id
            title
            description
            image_desktop {
              data {
                ...File
              }
            }
            image_mobile {
              data {
                ...File
              }
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useAboutUsPageQuery(baseOptions?: Apollo.QueryHookOptions<AboutUsPageQuery, AboutUsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutUsPageQuery, AboutUsPageQueryVariables>(AboutUsPageDocument, options);
      }
export function useAboutUsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutUsPageQuery, AboutUsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutUsPageQuery, AboutUsPageQueryVariables>(AboutUsPageDocument, options);
        }
export type AboutUsPageQueryHookResult = ReturnType<typeof useAboutUsPageQuery>;
export type AboutUsPageLazyQueryHookResult = ReturnType<typeof useAboutUsPageLazyQuery>;
export type AboutUsPageQueryResult = Apollo.QueryResult<AboutUsPageQuery, AboutUsPageQueryVariables>;
export const AllBrokersDocument = gql`
    query allBrokers {
  brokers {
    data {
      id
      attributes {
        name
        averageRating {
          overall
        }
        logo {
          data {
            ...File
          }
        }
        ratings {
          data {
            id
            attributes {
              review
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useAllBrokersQuery(baseOptions?: Apollo.QueryHookOptions<AllBrokersQuery, AllBrokersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBrokersQuery, AllBrokersQueryVariables>(AllBrokersDocument, options);
      }
export function useAllBrokersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBrokersQuery, AllBrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBrokersQuery, AllBrokersQueryVariables>(AllBrokersDocument, options);
        }
export type AllBrokersQueryHookResult = ReturnType<typeof useAllBrokersQuery>;
export type AllBrokersLazyQueryHookResult = ReturnType<typeof useAllBrokersLazyQuery>;
export type AllBrokersQueryResult = Apollo.QueryResult<AllBrokersQuery, AllBrokersQueryVariables>;
export const BrokerByIdDocument = gql`
    query brokerById($id: ID!) {
  brokers(filters: {id: {eq: $id}}) {
    data {
      id
      attributes {
        name
        well_performing
        logo {
          data {
            ...File
          }
        }
        ratings {
          data {
            id
          }
        }
        licenceStatus {
          id
          regulatoryStatus
          effectiveFrom
          licenseNumber
          licenceStrength
          regulator {
            data {
              id
              attributes {
                name
                rate
                regulatoryCountry
                logo {
                  data {
                    ...File
                  }
                }
              }
            }
          }
        }
        contactLinks {
          id
          facebook
          twitter
          linkedin
          instagram
          telegram
          messanger
          whatsapp
          viber
          youtube
          demo_account
          real_account
          website {
            id
            value
          }
        }
        accountTypes {
          data {
            id
            attributes {
              name
              minDeposit
              minTradeVolume
              marginCallLevel
              stopOutLevel
              commission
              EA
              Hedging
              Scalping
              NBP
              Islamic
              tradingPlatforms
              maxLeverage
              avgSpread
              currencies {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              trading_instruments {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              spread_types {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              features {
                id
                value
              }
            }
          }
        }
        contactOffice(pagination: {limit: 1000}) {
          id
          country
          officeType
          phoneNumber {
            id
            value
          }
          email {
            id
            value
          }
        }
        averageRating {
          id
          overall
          tradingCost
          tradingPlatform
          depositAndWithdrawal
          support
        }
        companyAndService {
          id
          country
          city
          address
          founded
          brokerType
          timeZone
          creditProfile
          websiteLanguage
          customerServiceBy
          supportedLanguage
          features {
            id
            value
          }
          tradingPlatform {
            id
            name
            description
          }
        }
        features {
          id
          value
        }
        depositAndWithdrawal {
          id
          depositMethod(pagination: {limit: 1000}) {
            id
            commission
            exchangeRate
            processingTime
            payment_method {
              data {
                id
                attributes {
                  name
                  logo {
                    data {
                      ...File
                    }
                  }
                }
              }
            }
          }
          withdrawalMethod(pagination: {limit: 1000}) {
            id
            commission
            exchangeRate
            processingTime
            payment_method {
              data {
                id
                attributes {
                  name
                  createdAt
                  updatedAt
                  logo {
                    data {
                      ...File
                    }
                  }
                }
              }
            }
          }
          features {
            id
            value
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useBrokerByIdQuery(baseOptions: Apollo.QueryHookOptions<BrokerByIdQuery, BrokerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerByIdQuery, BrokerByIdQueryVariables>(BrokerByIdDocument, options);
      }
export function useBrokerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerByIdQuery, BrokerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerByIdQuery, BrokerByIdQueryVariables>(BrokerByIdDocument, options);
        }
export type BrokerByIdQueryHookResult = ReturnType<typeof useBrokerByIdQuery>;
export type BrokerByIdLazyQueryHookResult = ReturnType<typeof useBrokerByIdLazyQuery>;
export type BrokerByIdQueryResult = Apollo.QueryResult<BrokerByIdQuery, BrokerByIdQueryVariables>;
export const BrokerNewsDocument = gql`
    query brokerNews($newsId: ID!) {
  brokerNews(id: $newsId) {
    data {
      id
      attributes {
        title
        description
        date
        article
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useBrokerNewsQuery(baseOptions: Apollo.QueryHookOptions<BrokerNewsQuery, BrokerNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerNewsQuery, BrokerNewsQueryVariables>(BrokerNewsDocument, options);
      }
export function useBrokerNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerNewsQuery, BrokerNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerNewsQuery, BrokerNewsQueryVariables>(BrokerNewsDocument, options);
        }
export type BrokerNewsQueryHookResult = ReturnType<typeof useBrokerNewsQuery>;
export type BrokerNewsLazyQueryHookResult = ReturnType<typeof useBrokerNewsLazyQuery>;
export type BrokerNewsQueryResult = Apollo.QueryResult<BrokerNewsQuery, BrokerNewsQueryVariables>;
export const BrokerNewsByIdsDocument = gql`
    query brokerNewsByIds($page: Int, $pageSize: Int, $brokerIds: [ID!]) {
  brokersNews(
    filters: {broker: {id: {in: $brokerIds}}}
    sort: ["createdAt:desc"]
    pagination: {page: $page, pageSize: $pageSize}
  ) {
    data {
      id
      attributes {
        title
        description
        date
        article
        image {
          data {
            ...File
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useBrokerNewsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<BrokerNewsByIdsQuery, BrokerNewsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerNewsByIdsQuery, BrokerNewsByIdsQueryVariables>(BrokerNewsByIdsDocument, options);
      }
export function useBrokerNewsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerNewsByIdsQuery, BrokerNewsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerNewsByIdsQuery, BrokerNewsByIdsQueryVariables>(BrokerNewsByIdsDocument, options);
        }
export type BrokerNewsByIdsQueryHookResult = ReturnType<typeof useBrokerNewsByIdsQuery>;
export type BrokerNewsByIdsLazyQueryHookResult = ReturnType<typeof useBrokerNewsByIdsLazyQuery>;
export type BrokerNewsByIdsQueryResult = Apollo.QueryResult<BrokerNewsByIdsQuery, BrokerNewsByIdsQueryVariables>;
export const BrokerNewsListDocument = gql`
    query brokerNewsList($page: Int, $pageSize: Int, $brokerId: ID!) {
  brokersNews(
    filters: {broker: {id: {eq: $brokerId}}}
    sort: ["createdAt:desc"]
    pagination: {page: $page, pageSize: $pageSize}
  ) {
    data {
      id
      attributes {
        title
        description
        date
        article
        image {
          data {
            ...File
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useBrokerNewsListQuery(baseOptions: Apollo.QueryHookOptions<BrokerNewsListQuery, BrokerNewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerNewsListQuery, BrokerNewsListQueryVariables>(BrokerNewsListDocument, options);
      }
export function useBrokerNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerNewsListQuery, BrokerNewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerNewsListQuery, BrokerNewsListQueryVariables>(BrokerNewsListDocument, options);
        }
export type BrokerNewsListQueryHookResult = ReturnType<typeof useBrokerNewsListQuery>;
export type BrokerNewsListLazyQueryHookResult = ReturnType<typeof useBrokerNewsListLazyQuery>;
export type BrokerNewsListQueryResult = Apollo.QueryResult<BrokerNewsListQuery, BrokerNewsListQueryVariables>;
export const BrokerPageDocument = gql`
    query brokerPage {
  brokerPage {
    data {
      id
      attributes {
        title
        subtitle
        show_top_filter
      }
    }
  }
}
    `;
export function useBrokerPageQuery(baseOptions?: Apollo.QueryHookOptions<BrokerPageQuery, BrokerPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokerPageQuery, BrokerPageQueryVariables>(BrokerPageDocument, options);
      }
export function useBrokerPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokerPageQuery, BrokerPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokerPageQuery, BrokerPageQueryVariables>(BrokerPageDocument, options);
        }
export type BrokerPageQueryHookResult = ReturnType<typeof useBrokerPageQuery>;
export type BrokerPageLazyQueryHookResult = ReturnType<typeof useBrokerPageLazyQuery>;
export type BrokerPageQueryResult = Apollo.QueryResult<BrokerPageQuery, BrokerPageQueryVariables>;
export const BrokersDocument = gql`
    query brokers($page: Int, $pageSize: Int, $country: String, $sort: [String]) {
  brokers(
    filters: {companyAndService: {country: {eq: $country}}}
    pagination: {page: $page, pageSize: $pageSize}
    sort: $sort
  ) {
    data {
      id
      attributes {
        name
        well_performing
        logo {
          data {
            id
            attributes {
              url
            }
          }
        }
        ratings {
          data {
            id
          }
        }
        licenceStatus {
          id
          regulator {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
        contactLinks {
          id
          website {
            value
          }
        }
        accountTypes {
          data {
            id
            attributes {
              name
              minDeposit
              maxLeverage
              avgSpread
            }
          }
        }
        averageRating {
          overall
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useBrokersQuery(baseOptions?: Apollo.QueryHookOptions<BrokersQuery, BrokersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokersQuery, BrokersQueryVariables>(BrokersDocument, options);
      }
export function useBrokersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokersQuery, BrokersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokersQuery, BrokersQueryVariables>(BrokersDocument, options);
        }
export type BrokersQueryHookResult = ReturnType<typeof useBrokersQuery>;
export type BrokersLazyQueryHookResult = ReturnType<typeof useBrokersLazyQuery>;
export type BrokersQueryResult = Apollo.QueryResult<BrokersQuery, BrokersQueryVariables>;
export const BrokersByIdDocument = gql`
    query brokersById($ids: [ID]!) {
  brokers(filters: {id: {in: $ids}}) {
    data {
      id
      attributes {
        name
        broker_news {
          data {
            id
            attributes {
              title
              description
              date
              broker {
                data {
                  id
                }
              }
            }
          }
        }
        well_performing
        logo {
          data {
            ...File
          }
        }
        ratings {
          data {
            id
          }
        }
        licenceStatus {
          id
          regulatoryStatus
          effectiveFrom
          licenseNumber
          regulator {
            data {
              id
              attributes {
                name
                rate
                regulatoryCountry
                logo {
                  data {
                    ...File
                  }
                }
              }
            }
          }
        }
        contactLinks {
          id
          facebook
          twitter
          linkedin
          instagram
          telegram
          messanger
          whatsapp
          viber
          youtube
          demo_account
          real_account
          website {
            id
            value
          }
        }
        accountTypes {
          data {
            id
            attributes {
              name
              minDeposit
              minTradeVolume
              marginCallLevel
              stopOutLevel
              commission
              EA
              Hedging
              Scalping
              NBP
              Islamic
              tradingPlatforms
              maxLeverage
              avgSpread
              currencies {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              trading_instruments {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              spread_types {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              features {
                id
                value
              }
            }
          }
        }
        contactOffice {
          id
          country
          officeType
          phoneNumber {
            id
            value
          }
          email {
            id
            value
          }
        }
        averageRating {
          id
          overall
          tradingCost
          tradingPlatform
          depositAndWithdrawal
          support
        }
        companyAndService {
          id
          country
          city
          address
          founded
          brokerType
          timeZone
          creditProfile
          websiteLanguage
          customerServiceBy
          supportedLanguage
          fifo
          features {
            id
            value
          }
          tradingPlatform {
            id
            name
            description
          }
        }
        features {
          id
          value
        }
        depositAndWithdrawal {
          id
          depositMethod {
            id
            commission
            exchangeRate
            processingTime
            payment_method {
              data {
                id
                attributes {
                  name
                  logo {
                    data {
                      ...File
                    }
                  }
                }
              }
            }
          }
          withdrawalMethod {
            id
            commission
            exchangeRate
            processingTime
            payment_method {
              data {
                id
                attributes {
                  name
                  createdAt
                  updatedAt
                  logo {
                    data {
                      ...File
                    }
                  }
                }
              }
            }
          }
          features {
            id
            value
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useBrokersByIdQuery(baseOptions: Apollo.QueryHookOptions<BrokersByIdQuery, BrokersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokersByIdQuery, BrokersByIdQueryVariables>(BrokersByIdDocument, options);
      }
export function useBrokersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokersByIdQuery, BrokersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokersByIdQuery, BrokersByIdQueryVariables>(BrokersByIdDocument, options);
        }
export type BrokersByIdQueryHookResult = ReturnType<typeof useBrokersByIdQuery>;
export type BrokersByIdLazyQueryHookResult = ReturnType<typeof useBrokersByIdLazyQuery>;
export type BrokersByIdQueryResult = Apollo.QueryResult<BrokersByIdQuery, BrokersByIdQueryVariables>;
export const BrokersByNameDocument = gql`
    query brokersByName($name: String!) {
  brokers(filters: {name: {containsi: $name}}) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useBrokersByNameQuery(baseOptions: Apollo.QueryHookOptions<BrokersByNameQuery, BrokersByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokersByNameQuery, BrokersByNameQueryVariables>(BrokersByNameDocument, options);
      }
export function useBrokersByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokersByNameQuery, BrokersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokersByNameQuery, BrokersByNameQueryVariables>(BrokersByNameDocument, options);
        }
export type BrokersByNameQueryHookResult = ReturnType<typeof useBrokersByNameQuery>;
export type BrokersByNameLazyQueryHookResult = ReturnType<typeof useBrokersByNameLazyQuery>;
export type BrokersByNameQueryResult = Apollo.QueryResult<BrokersByNameQuery, BrokersByNameQueryVariables>;
export const FooterDocument = gql`
    query footer {
  footer {
    data {
      id
      attributes {
        instagram
        twitter
        linkedin
        telegram
        youtube
        copyright
        logo {
          data {
            ...File
          }
        }
        navigation_links {
          ...Link
        }
        contact_links {
          ...Link
        }
        policy_list {
          ...Link
        }
      }
    }
  }
}
    ${FileFragmentDoc}
${LinkFragmentDoc}`;
export function useFooterQuery(baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
      }
export function useFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
        }
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<FooterQuery, FooterQueryVariables>;
export const HeaderDocument = gql`
    query header {
  header {
    data {
      id
      attributes {
        instagram
        twitter
        youtube
        logo_1 {
          data {
            ...File
          }
        }
        logo_2 {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useHeaderQuery(baseOptions?: Apollo.QueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
      }
export function useHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
        }
export type HeaderQueryHookResult = ReturnType<typeof useHeaderQuery>;
export type HeaderLazyQueryHookResult = ReturnType<typeof useHeaderLazyQuery>;
export type HeaderQueryResult = Apollo.QueryResult<HeaderQuery, HeaderQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      id
      attributes {
        hero {
          id
          title
          image_desktop {
            data {
              ...File
            }
          }
          image_mobile {
            data {
              ...File
            }
          }
        }
        helpSection {
          id
          title
          description
          visible
          card {
            id
            name
            description
          }
        }
        expertise {
          id
          title
          visible
          card {
            id
            title
            subtitle
            description
            image_large {
              data {
                ...File
              }
            }
            image_small {
              data {
                ...File
              }
            }
          }
          button {
            id
            name
            url
          }
        }
        freeSolutions {
          id
          title
          description
          visible
          item {
            id
            name
            description
          }
        }
        address {
          id
          title
          description
          phone
          email
          address {
            id
            name
            url
          }
          supported {
            id
            name
            url
          }
          visible
        }
        topic_list {
          id
          title
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NewsByIdDocument = gql`
    query newsById($id: ID!) {
  news(id: $id) {
    data {
      id
      attributes {
        title
        description
        date
        article
        latest
        top
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useNewsByIdQuery(baseOptions: Apollo.QueryHookOptions<NewsByIdQuery, NewsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsByIdQuery, NewsByIdQueryVariables>(NewsByIdDocument, options);
      }
export function useNewsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsByIdQuery, NewsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsByIdQuery, NewsByIdQueryVariables>(NewsByIdDocument, options);
        }
export type NewsByIdQueryHookResult = ReturnType<typeof useNewsByIdQuery>;
export type NewsByIdLazyQueryHookResult = ReturnType<typeof useNewsByIdLazyQuery>;
export type NewsByIdQueryResult = Apollo.QueryResult<NewsByIdQuery, NewsByIdQueryVariables>;
export const NewsListDocument = gql`
    query NewsList($page: Int, $pageSize: Int, $filter: NewsFiltersInput!) {
  newsList(
    filters: $filter
    sort: ["createdAt:desc"]
    pagination: {page: $page, pageSize: $pageSize}
  ) {
    data {
      id
      attributes {
        title
        description
        date
        latest
        top
        image {
          data {
            ...File
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useNewsListQuery(baseOptions: Apollo.QueryHookOptions<NewsListQuery, NewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsListQuery, NewsListQueryVariables>(NewsListDocument, options);
      }
export function useNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsListQuery, NewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsListQuery, NewsListQueryVariables>(NewsListDocument, options);
        }
export type NewsListQueryHookResult = ReturnType<typeof useNewsListQuery>;
export type NewsListLazyQueryHookResult = ReturnType<typeof useNewsListLazyQuery>;
export type NewsListQueryResult = Apollo.QueryResult<NewsListQuery, NewsListQueryVariables>;
export const NewsPageDocument = gql`
    query newsPage {
  newsPage {
    data {
      id
      attributes {
        hero {
          id
          title
          image_desktop {
            data {
              ...File
            }
          }
          image_mobile {
            data {
              ...File
            }
          }
        }
        top_news {
          id
          title
          subtitle
          visible
        }
        latest_news {
          id
          title
          subtitle
          visible
        }
        subscribe {
          id
          title
          subtitle
          visible
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useNewsPageQuery(baseOptions?: Apollo.QueryHookOptions<NewsPageQuery, NewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsPageQuery, NewsPageQueryVariables>(NewsPageDocument, options);
      }
export function useNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsPageQuery, NewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsPageQuery, NewsPageQueryVariables>(NewsPageDocument, options);
        }
export type NewsPageQueryHookResult = ReturnType<typeof useNewsPageQuery>;
export type NewsPageLazyQueryHookResult = ReturnType<typeof useNewsPageLazyQuery>;
export type NewsPageQueryResult = Apollo.QueryResult<NewsPageQuery, NewsPageQueryVariables>;
export const PodcastDocument = gql`
    query podcast {
  podcast {
    data {
      id
      attributes {
        hero {
          id
          visible
          heading {
            id
            title
            subtitle
            description
            image_large {
              data {
                ...File
              }
            }
            image_small {
              data {
                ...File
              }
            }
          }
          button {
            ...Link
          }
        }
        intoduction {
          id
          title
          subtitle
          description
          visible
          image_large {
            data {
              ...File
            }
          }
          image_small {
            data {
              ...File
            }
          }
        }
        subscribe {
          id
          title
          subtitle
          description
          visible
          image_large {
            data {
              ...File
            }
          }
          image_small {
            data {
              ...File
            }
          }
          links {
            ...Link
          }
        }
        trends {
          id
          title
          visible
          cards {
            id
            title
            description
            visible
            image_large {
              data {
                ...File
              }
            }
            image_small {
              data {
                ...File
              }
            }
            link_url
            link_title
          }
        }
        contactForm {
          id
          title
          subtitle
          visible
        }
      }
    }
  }
}
    ${FileFragmentDoc}
${LinkFragmentDoc}`;
export function usePodcastQuery(baseOptions?: Apollo.QueryHookOptions<PodcastQuery, PodcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PodcastQuery, PodcastQueryVariables>(PodcastDocument, options);
      }
export function usePodcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PodcastQuery, PodcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PodcastQuery, PodcastQueryVariables>(PodcastDocument, options);
        }
export type PodcastQueryHookResult = ReturnType<typeof usePodcastQuery>;
export type PodcastLazyQueryHookResult = ReturnType<typeof usePodcastLazyQuery>;
export type PodcastQueryResult = Apollo.QueryResult<PodcastQuery, PodcastQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privacyPolicy {
    data {
      id
      attributes {
        title
        description
      }
    }
  }
}
    `;
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const ReviewsDocument = gql`
    query reviews($page: Int, $pageSize: Int, $filter: RatingFiltersInput!) {
  ratings(
    filters: $filter
    pagination: {page: $page, pageSize: $pageSize}
    sort: ["createdAt:desc"]
  ) {
    data {
      id
      attributes {
        email
        name
        country
        accountType
        howLongUsed
        review
        createdAt
        rate {
          id
          overall
          tradingCost
          tradingPlatform
          depositAndWithdrawal
          support
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useReviewsQuery(baseOptions: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
      }
export function useReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
        }
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const ReviewsByIdDocument = gql`
    query reviewsById($id: ID!) {
  rating(id: $id) {
    data {
      id
      attributes {
        rate {
          id
          tradingCost
          tradingPlatform
          support
          overall
          depositAndWithdrawal
        }
      }
    }
  }
}
    `;
export function useReviewsByIdQuery(baseOptions: Apollo.QueryHookOptions<ReviewsByIdQuery, ReviewsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsByIdQuery, ReviewsByIdQueryVariables>(ReviewsByIdDocument, options);
      }
export function useReviewsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsByIdQuery, ReviewsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsByIdQuery, ReviewsByIdQueryVariables>(ReviewsByIdDocument, options);
        }
export type ReviewsByIdQueryHookResult = ReturnType<typeof useReviewsByIdQuery>;
export type ReviewsByIdLazyQueryHookResult = ReturnType<typeof useReviewsByIdLazyQuery>;
export type ReviewsByIdQueryResult = Apollo.QueryResult<ReviewsByIdQuery, ReviewsByIdQueryVariables>;
export const ServicesPageDocument = gql`
    query servicesPage {
  servicesPage {
    data {
      id
      attributes {
        hero {
          id
          title
          subtitle
          visible
        }
        hero_cards {
          id
          title
          subtitle
          items {
            id
            value
          }
        }
        subscribe {
          id
          title
          subtitle
          visible
        }
        our_services {
          id
          title
          subtitle
          description
          visible
          cards {
            id
            name
            description
          }
          image {
            data {
              ...File
            }
          }
        }
        why_we {
          id
          title
          visible
          image {
            data {
              ...File
            }
          }
          items {
            id
            value
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useServicesPageQuery(baseOptions?: Apollo.QueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
      }
export function useServicesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
        }
export type ServicesPageQueryHookResult = ReturnType<typeof useServicesPageQuery>;
export type ServicesPageLazyQueryHookResult = ReturnType<typeof useServicesPageLazyQuery>;
export type ServicesPageQueryResult = Apollo.QueryResult<ServicesPageQuery, ServicesPageQueryVariables>;
export const TermsAndConditionDocument = gql`
    query termsAndCondition {
  termsAndCondition {
    data {
      id
      attributes {
        title
        description
      }
    }
  }
}
    `;
export function useTermsAndConditionQuery(baseOptions?: Apollo.QueryHookOptions<TermsAndConditionQuery, TermsAndConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsAndConditionQuery, TermsAndConditionQueryVariables>(TermsAndConditionDocument, options);
      }
export function useTermsAndConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsAndConditionQuery, TermsAndConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsAndConditionQuery, TermsAndConditionQueryVariables>(TermsAndConditionDocument, options);
        }
export type TermsAndConditionQueryHookResult = ReturnType<typeof useTermsAndConditionQuery>;
export type TermsAndConditionLazyQueryHookResult = ReturnType<typeof useTermsAndConditionLazyQuery>;
export type TermsAndConditionQueryResult = Apollo.QueryResult<TermsAndConditionQuery, TermsAndConditionQueryVariables>;