import { Col,Row } from "antd"
import { FC,useState } from "react"
import { VscMenu } from "react-icons/vsc"
import { useLocation } from "react-router"
import { useHeaderQuery } from "src/graphql"
import { isLiteHeaderColorText } from "src/helpers/isLiteHeaderColor"
import { useBreakpoint } from "src/hooks/useBreakPoint"
import Container from "../container"
import { Logo } from "../logo"
import { HeaderMenu } from "../menu"
import { BurgerMenuModal } from "../menu/burger-menu-modal"

const Header: FC = () => {
  const { data: headerData } = useHeaderQuery()
  const { xl } = useBreakpoint()
  const { pathname } = useLocation()
  const isLiteColor = isLiteHeaderColorText(pathname)
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false)

  const logoWhite = headerData?.header?.data?.attributes?.logo_1?.data?.attributes?.url 
  const logoBlack = headerData?.header?.data?.attributes?.logo_2?.data?.attributes?.url
  const logo = isLiteColor ? logoWhite : logoBlack

  const instaLink = headerData?.header?.data?.attributes?.instagram || ""
  const twitterLink = headerData?.header?.data?.attributes?.twitter || ""
  const youtube = headerData?.header?.data?.attributes?.youtube || ""

  return (
    <Container>
      <Row align={"middle"} justify={"start"}>
        <Row align={"middle"}>{logo ? <Logo img={logo} height={xl ? 20 : 16} /> : null}</Row>
        {xl ? (
          <HeaderMenu instaLink={instaLink} twitterLink={twitterLink} youtube={youtube} />
        ) : (
          <Col style={{ marginLeft: "auto", lineHeight: 0 }}>
            <button
              onClick={() => setOpenBurgerMenu(true)}
              type={"button"}
              style={{ lineHeight: 0, padding: "16px 0 ", color: isLiteColor ? "#fff" : "#000" }}
            >
              <VscMenu size={30} color={isLiteColor ? "#fff" : "#1B4481"} />
            </button>
          </Col>
        )}
      </Row>

      {!xl ? (
        <BurgerMenuModal
          openBurgerMenu={openBurgerMenu}
          setOpenBurgerMenu={setOpenBurgerMenu}
          logo={logoBlack}
          instaLink={instaLink}
          twitterLink={twitterLink}
          youtube={youtube}
        />
      ) : null}
    </Container>
  )
}

export { Header }
