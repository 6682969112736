import { Row } from "antd"
import { FC } from "react"
import { FaInstagram,FaLinkedin } from "react-icons/fa"
import { FaTelegram,FaXTwitter } from "react-icons/fa6"
import { SlSocialYoutube } from "react-icons/sl"
import { useFooterQuery } from "src/graphql"
import { useBreakpoint } from "src/hooks/useBreakPoint"
import { SocialMediaIcon } from "../social-media-icon"

const SocialMediaList: FC = () => {
  const { data } = useFooterQuery()
  const { xl } = useBreakpoint()
  const iconProps = { size: xl ? 24 : 20 }

  const socialMediaList = [{
    id: 1,
    link: data?.footer?.data?.attributes?.instagram || "",
    icon: FaInstagram,
    props: iconProps
  },
  {
    id: 2,
    link: data?.footer?.data?.attributes?.twitter || "",
    icon: FaXTwitter,
    props: iconProps
    },
  {
    id: 3,
    link: data?.footer?.data?.attributes?.youtube || "",
    icon: SlSocialYoutube,
    props: { size: xl ? 30 : 26 }
  },
  {
    id: 4,
    link: data?.footer?.data?.attributes?.linkedin || "",
    icon: FaLinkedin,
    props: iconProps
  },
  {
    id: 5,
    link: data?.footer?.data?.attributes?.telegram || "",
    icon: FaTelegram,
    props: iconProps
  }]

  return (
    <Row align={"middle"} justify={"center"} style={{ gap: xl?"32px":"26px", color: "#fff" }}>
      {socialMediaList.map(({ id, link, icon, props }) => (
        <SocialMediaIcon key={id} href={link} Icon={icon} iconProps={props} />
      ))}
    </Row>
  )
}
export { SocialMediaList }
