import { Button, Flex } from "antd"
import { FC } from "react"
import { IoClose } from "react-icons/io5"
import { Logo } from "src/components/logo"

interface ModalHeaderProps {
  logo?: string
  closeModal: () => void
}

export const ModalHeader: FC<ModalHeaderProps> = ({ logo, closeModal }) => {
  return (
    <Flex align={"center"} style={{ marginBottom: "30px" }}>
      {logo ? (
        <div onClick={closeModal}>
          <Logo img={logo} height={20} />
        </div>
      ) : null}
      <Button style={{ marginLeft: "auto", border: "none", padding: "8px 0" }} onClick={closeModal}>
        <IoClose size={30} color={"#1B4481"} />
      </Button>
    </Flex>
  )
}
