import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const Podcast = lazy<FC>(() => import("./podcast"))
const News = lazy<FC>(() => import("./news"))
const NewsDetails = lazy<FC>(() => import("./news-details"))
const Services = lazy<FC>(() => import("./services"))
const Brokers = lazy<FC>(() => import("./brockers"))
const BrokerCompare = lazy<FC>(() => import("./brokers-compare"))
const BrokerDetails = lazy<FC>(() => import("./broker-details"))
const BrokerNewsDetails = lazy<FC>(() => import("./broker-news-details"))
const AboutUs = lazy<FC>(() => import("./about-us"))
const ContactUs = lazy<FC>(() => import("./contact-us"))
const PrivacyPolicy = lazy<FC>(() => import("./privacy-policy"))
const TermsAndConditions = lazy<FC>(() => import("./terms-and-conditions"))
const NotFound = lazy<FC>(() => import("./not-found"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Brokers,
        path: "/brokers",
      },
      {
        Component: BrokerCompare,
        path: "/brokers/compare",
      },
      {
        Component: BrokerDetails,
        path: "/broker/:brokerId",
      },
      {
        Component: BrokerNewsDetails,
        path: "/brokerNews/:id",
      },
      {
        Component: AboutUs,
        path: "/about",
      },
      {
        Component: Podcast,
        path: "/podcast",
      },
      {
        Component: Services,
        path: "/services",
      },
      {
        Component: News,
        path: "/news",
      },
      {
        Component: ContactUs,
        path: "/contact-us",
      },
      {
        Component: NewsDetails,
        path: "/news/:id",
      },
      {
        Component: PrivacyPolicy,
        path: "/privacy-policy",
      },
      {
        Component: TermsAndConditions,
        path: "/terms-and-conditions",
      },
      {
        Component: NotFound,
        path: "*",
      },
    ],
  },
]

export default routes
