import { Flex } from "antd"
import { FC } from "react"
import { FaInstagram } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { SlSocialYoutube } from "react-icons/sl"
import { SocialMediaIcon } from "src/components/social-media-icon"
import { NavButtons } from "../nav-buttons"

interface ModalFooterProps {
  closeModal: () => void
  instaLink: string
  twitterLink: string
  youtube: string
}

export const ModalFooter: FC<ModalFooterProps> = ({ closeModal, instaLink, twitterLink, youtube }) => {
  const linkStyles = { gap: 12 }
  const iconProps = { size: 24 }

  return (
    <Flex vertical gap={24}>
      <NavButtons closeModal={closeModal} />

      <Flex justify={"space-around"} style={{ borderTop: "1px solid #D7DCE5", paddingTop: "16px" }}>
        <SocialMediaIcon
          onClick={closeModal}
          linkStyles={linkStyles}
          href={instaLink}
          Icon={FaInstagram}
          iconProps={iconProps}
        />

        <SocialMediaIcon
          onClick={closeModal}
          linkStyles={linkStyles}
          href={twitterLink}
          Icon={FaXTwitter}
          iconProps={iconProps}
        />

        <SocialMediaIcon
          onClick={closeModal}
          linkStyles={linkStyles}
          href={youtube}
          Icon={SlSocialYoutube}
          iconProps={iconProps}
        />
      </Flex>
    </Flex>
  )
}
