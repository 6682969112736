import classNames from "classnames"
import { FC, ReactNode } from "react"
import styles from "./Container.module.scss"

interface ContainerProps {
  addStyles?: string
  children: ReactNode
}

const Container: FC<ContainerProps> = ({ addStyles, children }) => {
  return <div className={classNames(styles.container, addStyles)}>{children}</div>
}

export default Container
