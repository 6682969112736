import { Row } from "antd"
import { FC } from "react"
import { FaInstagram } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { SlSocialYoutube } from "react-icons/sl"
import { useLocation } from "react-router"
import { SocialMediaIcon } from "src/components/social-media-icon"
import { isLiteHeaderColorText } from "src/helpers/isLiteHeaderColor"

interface SocialMediaHeaderProps {
  instaLink: string
  twitterLink: string
  youtube: string
}

const SocialMediaHeader: FC<SocialMediaHeaderProps> = ({ instaLink, twitterLink, youtube }) => {
  const { pathname } = useLocation()
  const isLightColor = isLiteHeaderColorText(pathname)
  const iconProps = { size: 29 }

  return (
    <Row align={"middle"} justify={"center"} style={{ gap: "24px", color: isLightColor ? "#fff" : "#1D1D1B" }}>
      <SocialMediaIcon href={instaLink} Icon={FaInstagram} iconProps={iconProps} />
      <SocialMediaIcon href={twitterLink} Icon={FaXTwitter} iconProps={iconProps} />
      <SocialMediaIcon href={youtube} Icon={SlSocialYoutube} iconProps={{ size: 36 }} />
    </Row>
  )
}
export { SocialMediaHeader }
