interface FooterData {
  logo: string
  contactLinks: {
    id: string
    name: string
    link: string
    icon: string
    target: string
  }[]
  navLinks: {
    id: string
    name: string
    link: string
    icon: string
    target: string
  }[]
  policy: {
    id: string
    name: string
    link: string
    icon: string
    target: string
  }[]
  copyright: string
}

export const formattedData = (data: FooterQuery):FooterData => {
    const footer = data.footer?.data?.attributes

    return {
        logo: footer?.logo?.data?.attributes?.url || "",
        contactLinks: footer?.contact_links?.map(l => ({ id: l?.id || "", name: l?.title || "", link: l?.url || "", icon: l?.image?.data?.attributes?.url || "", target: l?.target || "" })) || [],
        navLinks: footer?.navigation_links?.map(l => ({ id: l?.id || "", name: l?.title || "", link: l?.url || "", icon: l?.image?.data?.attributes?.url || "", target: l?.target || "" })) || [],
        policy: footer?.policy_list?.map(l => ({ id: l?.id || "", name: l?.title || "", link: l?.url || "", icon: l?.image?.data?.attributes?.url || "", target: l?.target || "" })) || [],
        copyright: footer?.copyright || "",
    }
}
