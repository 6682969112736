import { ThemeConfig } from "antd/es/config-provider"
import { colors } from "./_colors"
import { typography } from "./_typography"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      bodyBg: "fff",
      headerBg: "transparent",
      footerBg: colors.colorPrimary,
      headerPadding: 0,
    },
    Select: {
      optionSelectedFontWeight: "initial",
      optionPadding: "8px 16px",
      optionHeight: 24,
      borderRadius: 0,
      borderRadiusLG: 0,
      colorBorder: "#c6c5c5",
      colorPrimaryHover: "none",
      boxShadowSecondary: "none",
      controlOutline: "none",
      optionActiveBg: "#EDF0F5",
    },

    Checkbox: {
      controlInteractiveSize: 20,
      borderRadiusSM: 0,
    },
    Table: {
      headerBg: "#1B4481",
      headerColor: "#fff",
    },
    Pagination: {
      colorPrimaryHover: "none",
      colorPrimaryBorder: "none",
      colorBgTextHover: "none",
      colorPrimary: "none",
    },
  },
  token: {
    ...colors,
    ...typography,
  },
}

export default theme
