import { FC } from "react"

import classNames from "classnames"
import { NavLink, useLocation } from "react-router-dom"
import { isLiteHeaderColorText } from "src/helpers/isLiteHeaderColor"
import { useBreakpoint } from "src/hooks/useBreakPoint"
import styles from "./NavList.module.scss"

const linkList = [
  {
    id: 1,
    name: "Brokers",
    link: "/brokers",
  },
  {
    id: 2,
    name: "Podcast",
    link: "/podcast",
  },
  {
    id: 3,
    name: "News",
    link: "/news",
  },
  {
    id: 4,
    name: "Services",
    link: "/services",
  },
  {
    id: 5,
    name: "About Us",
    link: "/about",
  },
]

const NavList: FC<{ closeModal?: () => void }> = ({ closeModal }) => {
  const { pathname } = useLocation()
  const { xl } = useBreakpoint()
  const isLightColor = isLiteHeaderColorText(pathname) && xl

  return (
    <ul className={styles.linkList}>
      {linkList.map(el => (
        <li key={el.id}>
          <NavLink
            to={el.link}
            onClick={closeModal}
            className={({ isActive }) =>
              classNames(styles.link, {
                [styles.activeLight]: isActive && isLightColor,
                [styles.activeDark]: isActive && !isLightColor,
                [styles.inactiveLight]: !isActive && isLightColor,
                [styles.inactiveDark]: !isActive && !isLightColor,
              })
            }
          >
            {el.name}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}
export { NavList }
