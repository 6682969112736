import { FC } from "react"
import { useNavigate } from "react-router"
import { CustomButton } from "src/components/custom-button"
import styles from "./NavButtons.module.scss"

export const NavButtons: FC<{ closeModal?: () => void }> = ({ closeModal }) => {
  const navigate = useNavigate()

  const handleContactUsClick = () => {
    navigate("/contact-us")
    closeModal && closeModal()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contactUsWrapper}>
        <CustomButton text={"Contact Us"} onClick={handleContactUsClick} color={"#FFF"} />
      </div>
    </div>
  )
}
