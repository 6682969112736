import { Button, ButtonProps, Typography } from "antd"
import { FC } from "react"
import styles from "./index.module.scss"

interface CustomButtonProps extends ButtonProps {
  text: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  color?: string
  backgroundColor?: string
  htmlType?: "button" | "submit" | "reset"
}

export const CustomButton: FC<CustomButtonProps> = ({
  text = "Click",
  disabled = false,
  onClick,
  color,
  backgroundColor,
  htmlType = "button",
  ...restProps
}) => {
  return (
    <Button
      htmlType={htmlType}
      type={"primary"}
      onClick={onClick}
      disabled={disabled}
      style={{
        padding: "0 24px",
        border: "none",
        height: "48px",
        boxShadow: "none",
        backgroundColor: backgroundColor,
        borderRadius: 0,
        width: "100%",
      }}
      {...restProps}
    >
      <Typography.Paragraph className={styles.text} style={{ margin: 0, color: color ? color : "inherit" }}>
        {text}
      </Typography.Paragraph>
    </Button>
  )
}
