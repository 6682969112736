import { Modal } from "antd"
import { FC } from "react"
import { NavList } from "../nav-list"
import { ModalFooter } from "./ModalFooter"
import { ModalHeader } from "./ModalHeader"

interface BurgerMenuModalProps {
  openBurgerMenu: boolean
  setOpenBurgerMenu: React.Dispatch<React.SetStateAction<boolean>>
  logo?: string
  instaLink: string
  twitterLink: string
  youtube: string
}

export const BurgerMenuModal: FC<BurgerMenuModalProps> = ({
  openBurgerMenu,
  setOpenBurgerMenu,
  logo,
  instaLink,
  twitterLink,
  youtube,
}) => {
  const closeModal = () => setOpenBurgerMenu(false)

  return (
    <Modal
      title={<ModalHeader logo={logo} closeModal={closeModal} />}
      footer={<ModalFooter closeModal={closeModal} instaLink={instaLink} twitterLink={twitterLink} youtube={youtube} />}
      open={openBurgerMenu}
      onCancel={closeModal}
      closeIcon={null}
      centered
    >
      <NavList closeModal={closeModal} />
    </Modal>
  )
}
