import { FC, ReactNode } from "react"
import { IconBaseProps } from "react-icons"

interface SocialMediaIconProps {
  href: string
  Icon: FC<IconBaseProps>
  iconProps?: IconBaseProps
  children?: ReactNode
  linkStyles?: React.CSSProperties
  onClick?: () => void
}

export const SocialMediaIcon: FC<SocialMediaIconProps> = ({ href, Icon, iconProps, linkStyles, onClick, children }) => (
  <a
    onClick={onClick}
    style={{ display: "flex", alignItems: "center", ...linkStyles }}
    href={href}
    target={"_blank"}
    rel={"noreferrer"}
  >
    <Icon {...iconProps} />
    {children}
  </a>
)
