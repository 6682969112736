import { Col, Row } from "antd"
import { FC } from "react"

import { NavButtons } from "./nav-buttons"
import { NavList } from "./nav-list"
import { SocialMediaHeader } from "./social-media-header"

interface HeaderMenuProps {
  instaLink: string
  twitterLink: string
  youtube: string
}

const HeaderMenu: FC<HeaderMenuProps> = ({ instaLink, twitterLink, youtube }) => {
  return (
    <Row align={"middle"} justify={"center"} style={{ marginLeft: "auto" }}>
      <Col style={{ marginRight: "103px" }}>
        <NavList />
      </Col>

      <Col style={{ marginRight: "90px" }}>
        <SocialMediaHeader instaLink={instaLink} twitterLink={twitterLink} youtube={youtube} />
      </Col>

      <NavButtons />
    </Row>
  )
}
export { HeaderMenu }
